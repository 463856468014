<template>
  <FAQComponent/>
</template>

<script>

const FAQComponent = () => import('../components/main/FAQComponent')

export default {
  name: 'FAQ',
  components: { FAQComponent }
}
</script>

<style scoped>

</style>
